import {
  Tabs as RawTabs,
  TabsProps as RawTabsProps,
  TabProps as RawTabProps,
  TabsPanelProps as RawTabPanelProps,
} from '@mantine/core';
import colors from 'common/styles/colors';
import React from 'react';

import { tabsStyle } from './styles.css';
import Text from '../text';

interface TabsHeader extends Omit<RawTabProps, 'value' | 'children' | 'icon'> {
  label: string;
  icon: (size: number, color: string) => React.ReactNode;
}
interface TabContent extends Omit<RawTabPanelProps, 'value' | 'children'> {
  content: React.ReactNode;
}
interface Item {
  value: string;
  header: TabsHeader;
  panel?: TabContent;
}

interface Props extends Omit<RawTabsProps, 'children'> {
  grow?: boolean;
  position?: 'left' | 'right' | 'center' | 'apart';
  items: Item[];
  onAfterValueChange?: (value: string) => void;
}

export default function Tabs(props: Props) {
  const { items, grow, position = 'left', onAfterValueChange, ...rest } = props;
  return (
    <RawTabs {...rest}>
      <RawTabs.List grow={grow} position={position}>
        {items.map((item) => (
          <RawTabs.Tab
            value={item.value}
            {...item.header}
            onClick={(e) => {
              item.header?.onClick?.(e);
              onAfterValueChange?.(item.value);
            }}
            icon={item.header.icon(16, colors.productNormal)}
          >
            <Text textVariant="BodyBoldDefault" className={tabsStyle.label}>
              {item.header.label}
            </Text>
          </RawTabs.Tab>
        ))}
      </RawTabs.List>

      {items.map((item) => (
        <RawTabs.Panel value={item.value} {...item.panel}>
          {item?.panel?.content || <></>}
        </RawTabs.Panel>
      ))}
    </RawTabs>
  );
}
