import { DraggerIcon, TrashIcon } from 'common/assets';
import { Button } from 'components/elements/button';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { UseFormSetValue } from 'react-hook-form';

import { dndStyles } from './styles.css';
import Separator from '../separator';

interface OnAfterChangeInputType {
  source: number;
  destination: number;
  sourceName: string;
  destinationName: string;
}

interface Data {
  children: React.ReactNode;
  customId: string;
  noDelete?: boolean;
}

interface Props {
  init: Data[];
  setValue: UseFormSetValue<any>;
  onRemove: (id: string) => void;
  editable?: boolean;
  withNumbering?: boolean;
}

export function DNDComponent(props: Props) {
  const [data, setData] = React.useState<Data[]>([]);
  const { init, setValue, onRemove, editable, withNumbering } = props;
  React.useEffect(() => {
    setData(init);
  }, [init]);

  const onChangeOrder = React.useCallback(
    (data: OnAfterChangeInputType) => {
      setValue(`${data.destinationName}.order`, data.source);
      setValue(`${data.sourceName}.order`, data.destination);
    },
    [setValue],
  );

  const dragHandler = React.useCallback(
    (destination, source) => {
      setData((prev) => {
        const data = [...prev];
        const temp = data[destination];
        data[destination] = data[source];
        data[source] = temp;
        onChangeOrder({
          source,
          destination,
          sourceName: (data[destination] as any)?.name,
          destinationName: (data[source] as any)?.name,
        });
        return data;
      });
    },
    [onChangeOrder],
  );

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        dragHandler(destination?.index, source.index);
      }}
    >
      <Droppable droppableId={`${data.length}-drop`} direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {data.map((item, index) => (
              <>
                <Draggable index={index} draggableId={`${index}-index`}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div className={dndStyles.outerContainer}>
                        {withNumbering && (
                          <>
                            <Text textVariant="BodyDefault">{index + 1}</Text>
                            <Separator gap={16} direction="horizontal" />
                          </>
                        )}
                        <div className={dndStyles.container}>
                          <div
                            {...provided.dragHandleProps}
                            className={dndStyles.dragger}
                          >
                            <DraggerIcon size={24} />
                          </div>
                          <div className={moduleStyles.flex1}>
                            {item?.children}
                          </div>
                        </div>
                        {editable && (
                          <Button
                            variant="tertiary"
                            error
                            type="button"
                            onClick={() => onRemove(item?.customId)}
                            className={
                              item?.noDelete ? dndStyles.notVisible : ''
                            }
                          >
                            <TrashIcon size={24} />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
                <Separator gap={16} direction="vertical" />
              </>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
